<template>
  <div class="gigawhat-user_role_tab">
    <p class="gigawhat-tab_description">Choisir un ou plusieurs rôles:</p>
    <div class="accordion-items_list user-roles_tab">
      <div v-for="role in roles" class="role-card_3" :key="role.id">
        <div class="user-role_card">
          <input
            :id="'Role-' + role.roleType"
            type="radio"
            :value="role"
            v-model="$v.userForm.role.$model"
          />
          <label for="" class="content-div">
            <h5>{{ role.name }}</h5>
            <p>{{ role.description }}</p>
          </label>
        </div>
      </div>
      <div class="role-card_3" v-if="false">
        <div class="add-new_role">
          <router-link :to="{ name: 'Roles' }" tag="button">
            <i class="add-new-icon">
              <svg
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.5 0C10.08 0 0 10.08 0 22.5C0 34.92 10.08 45 22.5 45C34.92 45 45 34.92 45 22.5C45 10.08 34.92 0 22.5 0ZM31.5 24.75H24.75V31.5C24.75 32.7375 23.7375 33.75 22.5 33.75C21.2625 33.75 20.25 32.7375 20.25 31.5V24.75H13.5C12.2625 24.75 11.25 23.7375 11.25 22.5C11.25 21.2625 12.2625 20.25 13.5 20.25H20.25V13.5C20.25 12.2625 21.2625 11.25 22.5 11.25C23.7375 11.25 24.75 12.2625 24.75 13.5V20.25H31.5C32.7375 20.25 33.75 21.2625 33.75 22.5C33.75 23.7375 32.7375 24.75 31.5 24.75Z"
                  fill="#132D48"
                />
              </svg>
            </i>
            <label>Ajouter un nouveau rôle</label>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userService } from "@/views/service/user.service";
import { mapGetters } from "vuex";

export default {
  name: "UserRole",
  props: ["userForm", "$v"],
  userForm: {
    lastName: "",
    firstName: "",
    email: "",
    isEnabled: false,
    isAdmin: false,
    role: []
  },
  computed: {
    ...mapGetters("account", ["getCurrentUser"])
  },
  data() {
    return {
      roles: []
    };
  },
  async mounted() {
    if (this.getCurrentUser.admin) {
      this.roles.push({
        id: 0,
        name: "Admin",
        description: "Rôle dédié aux administrateurs globaux",
        roleType: "ADMIN"
      });
    }
    this.roles.push(...(await userService.showRoles()));
    if (this.userForm.isAdmin) {
      this.userForm.role = this.roles[0];
    }
    this.roles.sort((role1, role2) =>
      role1.roleType > role2.roleType ? 1 : -1
    );
  }
};
</script>
<style scoped>
.content-div {
  word-wrap: break-word;
  max-height: 125.4px;
  min-height: 125.4px;
  overflow-y: auto;
}
</style>
